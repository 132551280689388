/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../variables";

.mdl-tooltip {
  transform: scale(0);
  transform-origin: top center;
  z-index: 999;
  background: $tooltip-background-color;
  border-radius: 2px;
  color: $tooltip-text-color;
  display: inline-block;
  font-size: $tooltip-font-size;
  font-weight: 500;
  line-height: 14px;
  max-width: 170px;
  position: fixed;
  top: -500px;
  left: -500px;
  padding: 8px;
  text-align: center;
}
.mdl-tooltip.is-active {
  animation: pulse 200ms $animation-curve-linear-out-slow-in forwards;
}

.mdl-tooltip--large {
  line-height: 14px;
  font-size: $tooltip-font-size-large;
  padding: 16px;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    // Fixes a weird bug with the interaction between Safari and the result of
    // the SASS compilation for the animation.
    // Essentially, we need to make sure that "50%" and "100%" don't get merged
    // into a single "50%, 100%" entry, so we need to avoid them having any
    // matching properties.
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
}
